@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "components/formFields";
@import "./setup/settings";

.login-page {
  .login-form-nav {
    margin-bottom: 1.25em;

    .tab-pane {
      margin-top: 1em;
    }
  }

  .login {
    margin-bottom: 0.938em;

    .error-message-form {
      padding: 0.625em;
      background-color: $danger;
      color: $state-danger-text;
      border-radius: 0.188em;
    }
  }
}

.registration-page {
  .privacy-policy {
    margin-top: 1rem;
  }
}

.login-banner {
  background-image: url("../images/account.jpg");
  background-position-y: 40%;
}

.equal-height .card {
  width: 100%;
}

.track-order-header {
  font-size: 1.75rem;
}

.request-password-title {
  font-size: 1.5rem;
}

// Variables
$grey1: #f5f5f5;
$grey2: #333333;
$alertBkg: #edd3d2;

.login-page {
  background: $grey1;
  padding-top: rem(80);
  padding-bottom: rem(150);

  @include media-breakpoint-down(md) {
    padding-top: rem(40);
    padding-bottom: rem(120);
  }
  @include media-breakpoint-down(sm) {
    padding-top: rem(30);
    padding-bottom: rem(80);
  }

  .login-container {
    max-width: rem(1360);
    margin: 0 auto;
  }

  .custom-control.custom-checkbox.remember-me {
    padding-left: 0;
  }
  .custom-control-label {
    padding-left: rem(30);
    font-size: rem(16);
    @include media-breakpoint-down(sm) {
      font-size: rem(14);
    }
  }
  .custom-control-label::before {
    left: 0 !important;
    top: 50% !important;
    transform: translateY(-50%);
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    left: 0 !important;
    top: 50% !important;
    transform: translateY(-50%);
  }
  label.checkout-checkbox {
    padding-left: rem(29);
  }

  .card {
    background: $white;
    border-radius: 0;
    border: none;
    margin-bottom: rem(30);

    @include media-breakpoint-down(xs) {
      margin-bottom: rem(15);
    }

    .card-header {
      font-weight: 400;
      font-size: rem(34);
      line-height: rem(40);
      color: $black;
      text-transform: uppercase;
      text-align: left;
      padding: rem(18) rem(40) rem(14) rem(40);

      @include media-breakpoint-down(md) {
        padding: rem(18) rem(20) rem(14) rem(20);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(22);
        line-height: rem(30);
        padding: rem(18) rem(15) rem(14) rem(15);
      }

      &.request-password-title {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    .card-body {
      padding: rem(20) rem(40) rem(40) rem(40);

      @include media-breakpoint-down(md) {
        padding: rem(20) rem(20) rem(40) rem(20);
      }
      @include media-breakpoint-down(xs) {
        padding: rem(20) rem(15) rem(20) rem(15);
      }

      .request-password-body {
        p:last-child {
          margin-bottom: rem(50);

          @include media-breakpoint-down(xs) {
            margin-bottom: rem(30);
          }
        }
      }

      .form-group:last-child {
        margin-bottom: 0;
      }

      p {
        @include media-breakpoint-down(xs) {
          font-size: rem(12);
        }
      }

      .nav-tabs {
        .nav-link {
          font-weight: 700;
          color: $black;
          position: relative;
          border: none;
          padding-bottom: rem(20);
          padding-top: rem(4);
          background-color: $white !important;

          @include media-breakpoint-down(md) {
            font-size: rem(16);
          }
          @include media-breakpoint-down(xs) {
            font-size: rem(14);
          }

          &.active,
          &:hover {
            background-color: $white !important;
          }

          &:after {
            content: "";
            position: absolute;
            z-index: 1;
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: rem(5);
            background: $black;
            opacity: 0;
            transition: all 0.15s ease-in-out;
          }

          &.active:after {
            opacity: 1;
          }
        }
      }

      .login {
        padding-top: rem(15);
      }
      .login-form-nav {
        margin-bottom: 0;
      }

      .btn-primary,
      .btn-outline-primary {
        margin-top: rem(15);

        @include media-breakpoint-down(xs) {
          font-size: rem(20);
          padding-top: rem(19);
          padding-bottom: rem(19);
        }
      }

      .btn-outline-primary {
        padding-left: rem(10);
        padding-right: rem(10);
        border-bottom: 1px solid $black;
      }

      .form-group {
        padding-top: rem(10);

        &.custom-checkbox {
          label {
            @include media-breakpoint-down(xs) {
              font-size: rem(12);
            }
          }
        }

        label {
          @include media-breakpoint-down(xs) {
            font-size: rem(14);
            line-height: rem(23);
          }
        }

        .form-control {
          @include media-breakpoint-down(xs) {
            height: rem(60);
          }
        }
      }

      .content-message-forgot {
        padding-top: rem(7);
        padding-bottom: rem(6);
      }

      .forgot-password {
        margin-left: auto;
        margin-top: rem(-7);

        a {
          @include media-breakpoint-down(xs) {
            font-size: rem(12);
          }
        }
      }

      .custom-checkbox-spaced {
        margin-top: rem(40);
        margin-bottom: rem(30);

        @include media-breakpoint-down(xs) {
          margin-top: rem(20);
        }

        label {
          font-size: rem(14);
        }
      }

      .top-spaced {
        margin-top: rem(40);

        @include media-breakpoint-down(xs) {
          margin-top: rem(20);
        }
      }

      .privacy-policy {
        font-size: rem(14);
        margin-top: rem(30);
        margin-bottom: rem(10);

        a {
          font-size: inherit;
        }
      }

      .alert {
        margin: rem(-32) rem(-40) rem(32) rem(-40);
        border-radius: 0;
        border: none;
        padding: rem(25) rem(40);
        color: $mexican-red;
        background: $alertBkg;

        @include media-breakpoint-down(md) {
          margin: rem(-32) rem(-20) rem(32) rem(-20);
          padding: rem(25) rem(20);
        }
        @include media-breakpoint-down(xs) {
          margin: rem(-32) rem(-15) rem(20) rem(-15);
          padding: rem(20) rem(15);
          font-size: rem(12);
        }

        b,
        strong {
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
}
